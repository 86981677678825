import eyeson, { Logger, LocalStorage, FeatureDetector } from 'eyeson';

const _actions = {
  joinleave: { enabled: false, state: false, canToggleState: true },
  videotoggle: { enabled: false, state: false, canToggleState: true },
  audiotoggle: { enabled: false, state: false, canToggleState: true },
  recordtoggle: { enabled: false, state: false, canToggleState: true },
  broadcasttoggle: { enabled: false, state: false, canToggleState: true },
  playbacktoggle: { enabled: false, state: false, canToggleState: true },
  presenttoggle: { enabled: false, state: false, canToggleState: true },
  screensharetoggle: { enabled: false, state: false, canToggleState: true },
  virtualbackgroundtoggle: {
    enabled: false,
    state: false,
    canToggleState: true,
  },
  pictureinpicturetoggle: {
    enabled: false,
    state: false,
    canToggleState: true,
  },
  fullscreentoggle: { enabled: false, state: false, canToggleState: true },
  lockmeeting: { enabled: false, state: false, canToggleState: true },
  setlayout: { enabled: false, state: true, canToggleState: false },
  setlayoutmap: { enabled: false, state: true, canToggleState: false },
  setlayer: { enabled: false, state: true, canToggleState: false },
  watermark: { enabled: false, state: true, canToggleState: false },
  clearlayer: { enabled: false, state: true, canToggleState: false },
  chatmessage: { enabled: false, state: true, canToggleState: false },
  reaction: { enabled: false, state: true, canToggleState: false },
  snapshot: { enabled: false, state: true, canToggleState: false },
  muteall: { enabled: false, state: true, canToggleState: false },
  copyguestlink: { enabled: false, state: true, canToggleState: false },
};

const _activePlaybacks = new Set();
const _wannaPlay = new Set();
const _diconnectListener = [];

const _symbol = '⌨️ ';
const _storageKey = 'streamdeck.meeting';

let _listeners = [];
let _ws = null;
let _accepted = false;
let _connected = false;
let _suggest_guest_names = true;

const isEnabled = () => {
  const setting = LocalStorage.load(_storageKey, { enabled: false });
  return setting.enabled;
};

const enableSetting = () => {
  LocalStorage.store(_storageKey, { enabled: true });
  return isEnabled;
};

const disableSetting = () => {
  LocalStorage.store(_storageKey, { enabled: false });
  return isEnabled;
};

const onDisconnect = (fn) => {
  _diconnectListener.push(fn);
};

const isSupported = () => !FeatureDetector.isSafari();

const connect = () => {
  return new Promise((resolve, reject) => {
    if (_ws) {
      return;
    }
    Logger.debug('Try connecting to stream deck plugin');
    _ws = new WebSocket('ws://127.0.0.1:33621', ['eyeson-streamdeck-json']);
    _ws.onopen = () => {
      Logger.debug('Streamdeck connected');
      _connected = true;
      initActions();
      updateDocumentTitle();
      registerPlaybackEvents();
      resolve();
    };
    _ws.onclose = ({ code, reason }) => {
      Logger.debug('Streamdeck closed', code, reason);
      _connected = false;
      unregisterPlaybackEvents();
      _wannaPlay.clear();
      _activePlaybacks.clear();
      _ws = null;
      updateDocumentTitle();
      reject();
      _diconnectListener.forEach((fn) => {
        fn();
      });
    };
    _ws.onmessage = async ({ data }) => {
      try {
        const event = JSON.parse(data);
        if (await handleEvent(event)) {
          return;
        }
        emit(event);
      } catch (error) {
        Logger.error('Streamdeck::onMessage', error);
      }
    };
  });
};

const updateDocumentTitle = () => {
  document.title = (_ws ? _symbol : '') + document.title.replace(_symbol, '');
};

const initActions = () => {
  Object.keys(_actions).forEach((key) => {
    _actions[key].enabled = false;
    _actions[key].state = _actions[key].canToggleState ? false : true;
  });
};

const registerPlaybackEvents = () => {
  eyeson.onEvent(handlePlaybackEvent);
};

const unregisterPlaybackEvents = () => {
  eyeson.offEvent(handlePlaybackEvent);
};

const setActions = (actions) => {
  if (!_ws) {
    return;
  }
  Object.keys(actions).forEach((key) => {
    if (
      actions[key].enabled !== _actions[key].enabled ||
      actions[key].state !== _actions[key].state
    ) {
      _actions[key].enabled = actions[key].enabled;
      _actions[key].state = actions[key].state;
      Logger.debug('Streamdeck set action', key, actions[key]);
      send({
        type: key,
        enabled: _actions[key].enabled,
        state: _actions[key].state,
      });
    }
  });
};

const onEvent = (fn) => {
  _listeners.push(fn);
};

const offEvent = (fn) => {
  if (typeof fn === 'function') {
    _listeners = _listeners.filter((callback) => callback !== fn);
  } else {
    _listeners.length = 0;
  }
};

const emit = (event) => {
  _listeners.forEach((fn) => {
    fn(event);
  });
};

const send = (data) => {
  if (_ws && _ws.readyState === WebSocket.OPEN) {
    try {
      _ws.send(JSON.stringify(data));
    } catch (error) {
      Logger.error('Streamdeck::send', error);
    }
  }
};

const handleEvent = async (event) => {
  const { type, settings, id } = event;
  if (type === 'playbacktoggle') {
    if (!event.state && settings.url === '') {
      return true;
    }
    togglePlayback(settings, type, id);
    return true;
  } else if (type === 'setlayout') {
    try {
      await setLayout(settings);
    } catch (error) {
      send({
        type,
        enabled: true,
        state: true,
        id,
        errorMessage: error.message,
      });
    }
    return true;
  } else if (type === 'setlayoutmap') {
    try {
      await setLayoutMap(settings);
    } catch (error) {
      send({
        type,
        enabled: true,
        state: true,
        id,
        errorMessage: error.message,
      });
    }
    return true;
  } else if (type === 'setlayer') {
    try {
      await setLayer(settings);
    } catch (error) {
      send({
        type,
        enabled: true,
        state: true,
        id,
        errorMessage: error.message,
      });
    }
    return true;
  } else if (type === 'clearlayer') {
    try {
      await clearLayer(settings);
    } catch (error) {
      send({
        type,
        enabled: true,
        state: true,
        id,
        errorMessage: error.message,
      });
    }
    return true;
  } else if (type === 'watermark') {
    try {
      await watermark(settings);
    } catch (error) {
      send({
        type,
        enabled: true,
        state: true,
        id,
        errorMessage: error.message,
      });
    }
    return true;
  } else if (type === 'chatmessage') {
    try {
      await chatMessage(settings);
    } catch (error) {
      send({
        type,
        enabled: true,
        state: true,
        id,
        errorMessage: error.message,
      });
    }
    return true;
  } else if (type === 'copyguestlink') {
    copyGuestlink(type, id);
    return true;
  } else if (type === 'get-guestlink') {
    getGuestlink(type);
    return true;
  }
  return false;
};

const handlePlaybackEvent = (event) => {
  if (event.type === 'room_ready') {
    if (Array.isArray(event.content.playbacks)) {
      event.content.playbacks.forEach(({ play_id }) => {
        _activePlaybacks.add(play_id);
        send({
          type: 'playbacktoggle',
          enabled: _accepted,
          state: false,
          playId: play_id,
        });
      });
    }
    const { suggest_guest_names } = event.content.options;
    _suggest_guest_names =
      suggest_guest_names === true || suggest_guest_names === 'true';
  } else if (event.type === 'accept') {
    _accepted = true;
  } else if (event.type === 'playback_update') {
    const allIds = event.playing.map((entry) => entry.play_id);
    if (_wannaPlay.size > 0) {
      _wannaPlay.forEach((playId) => {
        if (allIds.includes(playId)) {
          _wannaPlay.delete(playId);
          _activePlaybacks.add(playId);
          send({
            type: 'playbacktoggle',
            enabled: _accepted,
            state: false,
            playId,
          });
        }
      });
    }
    _activePlaybacks.forEach((playId) => {
      if (!allIds.includes(playId)) {
        _activePlaybacks.delete(playId);
        send({
          type: 'playbacktoggle',
          enabled: _accepted,
          state: true,
          playId,
        });
      }
    });
  } else if (event.type === 'error' || event.type === 'exit') {
    _accepted = false;
  }
};

const togglePlayback = async (
  { url, audio, loop, position, playId },
  type,
  id
) => {
  const { comApi } = eyeson.core;
  if (_activePlaybacks.has(playId)) {
    try {
      await comApi._request(`/rooms/${comApi.token}/playbacks/${playId}`, {
        method: 'DELETE',
      });
    } catch (error) {
      Logger.error('StreamdeckHelper::stopPlayback', error);
    }
    return;
  }
  try {
    const fd = new FormData();
    fd.set('playback[play_id]', playId);
    fd.set('playback[url]', url);
    fd.set('playback[audio]', audio);
    fd.set('playback[loop_count]', loop ? '-1' : '0');
    if (position === 'replace') {
      fd.set('playback[replacement_id]', eyeson.user.apiId);
    }
    _wannaPlay.add(playId);
    await postFormData(`${comApi.uri}/rooms/${comApi.token}/playbacks`, fd);
  } catch (error) {
    _wannaPlay.delete(playId);
    Logger.error('StreamdeckHelper::startPlayback', error);
    send({ type, enabled: true, state: true, id, errorMessage: error.message });
  }
};

const setLayout = async ({
  layout,
  objectFit,
  selfPosition,
  showNames,
  autofill,
  voiceActivation,
  users,
}) => {
  const { comApi } = eyeson.core;
  let layoutName = layout === 'auto' ? null : layout;
  if (objectFit === 'contain') {
    if (layoutName === 'one') {
      layoutName = 'aspect-fit';
    } else if (layoutName === 'present-upper-6') {
      layoutName = 'present-upper-6-aspect-fit';
    } else if (layoutName === 'present-vertical-9') {
      layoutName = 'present-vertical-9-aspect-fit';
    }
  }
  if (
    selfPosition === 'first' &&
    ['one', 'present-upper-6', 'present-vertical-9'].includes(layout)
  ) {
    users[0] = eyeson.user.apiId;
  }
  if (users) {
    const index = users.findIndex((user) => user === 'me');
    if (index > -1) {
      users[index] = eyeson.user.apiId;
    }
  }
  const params = {
    layout: autofill ? 'auto' : 'custom',
    name: layoutName,
    users,
    show_names: showNames,
  };
  if (params.layout === 'auto') {
    params.voice_activation = voiceActivation;
  }
  const response = await fetch(`${comApi.uri}/rooms/${comApi.token}/layout`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(params),
  });
  if (!response.ok) {
    let errorMessage = await getResponseErrorMessage(response);
    throw new Error(errorMessage);
  }
};

const setLayoutMap = async ({
  map,
  name,
  showNames,
  autofill,
  voiceActivation,
  users,
}) => {
  if (!map) {
    return;
  }
  const { comApi } = eyeson.core;
  const index = users.findIndex((user) => user === 'me');
  if (index > -1) {
    users[index] = eyeson.user.apiId;
  }
  const params = {
    layout: autofill ? 'auto' : 'custom',
    name,
    map,
    users,
    show_names: showNames,
  };
  if (params.layout === 'auto') {
    params.voice_activation = voiceActivation;
  }
  const response = await fetch(`${comApi.uri}/rooms/${comApi.token}/layout`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(params),
  });
  if (!response.ok) {
    let errorMessage = await getResponseErrorMessage(response);
    throw new Error(errorMessage);
  }
};

const setLayer = async ({
  zindex,
  file,
  fileType,
  fileName,
  url,
  text,
  title,
}) => {
  const { comApi } = eyeson.core;
  if (typeof url === 'string' && url !== '') {
    const fd = new FormData();
    fd.set('url', url);
    fd.set('z-index', zindex);
    await postFormData(`${comApi.uri}/rooms/${comApi.token}/layers`, fd);
  } else if (file && fileType) {
    const blob = await base64ToBlob(file, fileType);
    if (blob) {
      const fd = new FormData();
      fd.set('file', blob, fileName);
      fd.set('z-index', zindex);
      await postFormData(`${comApi.uri}/rooms/${comApi.token}/layers`, fd);
    }
  } else if (typeof text === 'string' && typeof title === 'string') {
    const fd = new FormData();
    if (title !== '') {
      fd.set('insert[title]', title);
    }
    if (text !== '') {
      fd.set('insert[content]', text);
    }
    fd.set('z-index', zindex);
    await postFormData(`${comApi.uri}/rooms/${comApi.token}/layers`, fd);
  }
};

const postFormData = async (url, formData) => {
  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });
  if (!response.ok) {
    let errorMessage = await getResponseErrorMessage(response);
    throw new Error(errorMessage);
  }
};

const getResponseErrorMessage = async (response) => {
  let errorMessage = `ComApiError: ${response.status}`;
  if (response.headers.get('content-type').startsWith('application/json')) {
    try {
      const { error } = await response.json();
      if (error) {
        errorMessage = error;
      }
    } catch (error) {}
  }
  return errorMessage;
};

const clearLayer = async ({ zindex }) => {
  if (zindex === 'all') {
    await Promise.all([clearLayoutRequest('1'), clearLayoutRequest('-1')]);
  } else {
    await clearLayoutRequest(zindex);
  }
};

const clearLayoutRequest = async (zindex) => {
  const { comApi } = eyeson.core;
  await comApi._request(`/rooms/${comApi.token}/layers/${zindex}`, {
    method: 'DELETE',
  });
};

const watermark = async ({
  file,
  fileType,
  widthPercent,
  margin,
  position,
}) => {
  if (!(file && fileType)) {
    return;
  }
  const canvas = document.createElement('canvas');
  canvas.width = 1280;
  canvas.height = eyeson.options.widescreen ? 720 : 960;
  const context = canvas.getContext('2d');
  const img = await getImage({ base64: file, fileType });
  const prop = img.height / img.width;
  const width = Math.round(canvas.width * (widthPercent / 100));
  const height = Math.round(width * prop);
  let top = margin;
  let left = margin;
  if (position.includes('middle')) {
    top = canvas.height / 2 - height / 2;
  } else if (position.includes('bottom')) {
    top = canvas.height - margin - height;
  }
  if (position.includes('center')) {
    left = canvas.width / 2 - width / 2;
  } else if (position.includes('right')) {
    left = canvas.width - margin - width;
  }
  context.drawImage(img, 0, 0, img.width, img.height, left, top, width, height);
  const blob = await new Promise((resolve) => canvas.toBlob(resolve));
  const { comApi } = eyeson.core;
  const fd = new FormData();
  fd.set('file', blob, 'image.png');
  fd.set('z-index', '1');
  await postFormData(`${comApi.uri}/rooms/${comApi.token}/layers`, fd);
};

const getImage = ({ base64, fileType }) => {
  return new Promise((resolve, reject) => {
    const dataUrl = `data:${fileType};base64,${base64}`;
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject();
    };
    img.src = dataUrl;
  });
};

const chatMessage = async ({ message }) => {
  if (message === '') {
    return;
  }
  const { comApi } = eyeson.core;
  const fd = new FormData();
  fd.set('type', 'chat');
  fd.set('content', message);
  await postFormData(`${comApi.uri}/rooms/${comApi.token}/messages`, fd);
};

const copyGuestlink = (type, id) => {
  try {
    const url = new URL(window.location.origin);
    url.searchParams.set('guest', eyeson.room.guest_token);
    if (_suggest_guest_names === false) {
      url.searchParams.set('suggest', 'false');
    }
    send({ type, enabled: true, state: true, id, content: url.toString() });
  } catch (error) {
    send({
      type,
      enabled: true,
      state: true,
      id,
      errorMessage: error.message,
    });
  }
};

const getGuestlink = (type) => {
  try {
    const url = eyeson.links.guest_join;
    send({ type, enabled: true, state: true, content: url });
  } catch (error) {
    send({
      type,
      enabled: true,
      state: true,
      content: 'error',
    });
  }
};

const destroy = () => {
  if (_ws) {
    _ws.close();
    _ws = null;
  }
};

const base64ToBlob = async (base64, type) => {
  try {
    const dataUrl = `data:${type};base64,${base64}`;
    const blob = await (await fetch(dataUrl)).blob();
    return blob;
  } catch (error) {
    Logger.error('StreamdeckHelper::base64ToBlob', error);
  }
  return null;
};

const StreamdeckHelper = {
  init: () => {
    return connect();
  },
  isSupported,
  isEnabled,
  enableSetting,
  disableSetting,
  onEvent,
  offEvent,
  setActions,
  send,
  destroy,
  base64ToBlob,
  updateDocumentTitle,
  isConnected: () => _connected,
  onDisconnect,
};

export default StreamdeckHelper;
