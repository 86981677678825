import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import ForwardEvent from './ForwardEvent.js';
import ChangeLocalStreamEvent from './ChangeLocalStreamEvent.js';
import {
  isCameraStream,
  isVBGStream,
  hasAudio,
  hasVideo
} from './../utils/StreamHelpers.js';

const noChange = (lastPodiumSettings, msg) => {
  return Object.keys(lastPodiumSettings).every(
    key => lastPodiumSettings[key] === msg[key]
  );
};

class PodiumEvent extends BaseEvent {
  // eslint-disable-next-line max-statements
  handle(msg) {
    // For dev
    Logger.debug('PodiumEvent::handle', msg);
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (_session && !_session.externalStream) {
      this.scanMessageForLocalStreamOptimization(_session, msg);
    }

    new ForwardEvent(this.context).handle(msg);
  }

  // Optimization to avoid streaming when not on video podium.
  // First, we need a localStream, then we avoid adjustments when we initially
  // join the room (isSource) and finally if we're not on the podium,
  // but have a local camera stream with video, we change our local stream.
  //
  // We only re-activate the camera in the opposite case if the current
  // localStream was locallyChanged (through this optimization) since we
  // want to avoid situations where a user doesn't expect to be on the video
  // podium and we suddenly turned on their camera.
  //
  // Last case: someone else is presenting (screen share or canvas presentation)
  // we're still on the video podium but not actively presenting.
  // eslint-disable-next-line max-statements
  scanMessageForLocalStreamOptimization(session, msg) {
    const { localStream } = session;
    const { video, isSource, isPresenter, hasPresenter } = msg;

    if (!localStream) {
      return;
    }

    const streamSettings = {
      isCameraStream: isCameraStream(localStream),
      isVBGStream: isVBGStream(localStream),
      hasVideo: hasVideo(localStream),
      hasAudio: hasAudio(localStream),
      locallyChanged: localStream.locallyChanged
    };

    if (
      session.lastPodiumSettings &&
      noChange(
        session.lastPodiumSettings,
        Object.assign({}, msg, streamSettings)
      )
    ) {
      Logger.debug('scanMessageForLocalStreamOptimization: no change');
      return;
    }

    session.lastPodiumSettings = Object.assign(
      { video, isSource, isPresenter, hasPresenter },
      streamSettings
    );

    if (
      isSource &&
      ((!video &&
        (streamSettings.isCameraStream || streamSettings.isVBGStream)) ||
        (video &&
          !streamSettings.hasVideo &&
          streamSettings.locallyChanged &&
          !hasPresenter))
    ) {
      const options = {
        audio: streamSettings.hasAudio,
        video: video
      };
      new ChangeLocalStreamEvent(this.context).handle(options);
    }

    if (video && !isPresenter && hasPresenter) {
      new ChangeLocalStreamEvent(this.context).handle({
        audio: streamSettings.hasAudio,
        video: false
      });
    }
  }
}

export default PodiumEvent;
