/* eslint-disable max-lines */
import Logger from './Logger.js';
import 'webrtc-adapter';
import {
  stopTrack,
  getCanvasTracks,
  isCanvasPresentationStream
} from './utils/StreamHelpers.js';
import FeatureDetector from './FeatureDetector.js';
import { isObject } from './utils/utils.js';

const _iceCheckingTimeout = 3000;

const fixIOSHWDecoderBug = () => {
  // iOS 17.5 bugix VP9
  // iOS 18.0 bugix AV1
  // our solution is to remove vp9 and av1 codec from SDP for older systems
  // https://developer.apple.com/documentation/safari-release-notes/safari-17_5-release-notes#WebRTC
  // https://developer.apple.com/documentation/safari-release-notes/safari-18-release-notes#WebRTC
  // if FeatureDetector.isIOSDevice() && FeatureDetector.canSetCodecPreferences() && if no video codecs are selected
  const uAVersion = navigator.userAgent.match(/Version\/(\d+).(\d+)/);
  if (uAVersion && uAVersion.length > 1) {
    if (
      Number(uAVersion[1]) < 17 ||
      (Number(uAVersion[1]) === 17 && Number(uAVersion[2]) < 5)
    ) {
      return RTCRtpSender.getCapabilities('video').codecs.filter(
        ({ mimeType }) => mimeType !== 'video/VP9' && mimeType !== 'video/AV1'
      );
    }
    if (Number(uAVersion[1]) < 18) {
      return RTCRtpSender.getCapabilities('video').codecs.filter(
        ({ mimeType }) => mimeType !== 'video/AV1'
      );
    }
  }
  return null;
};

/**
 * SessionDescriptionHandler
 */
class SessionDescriptionHandler {
  // eslint-disable-next-line max-statements
  constructor(options) {
    this.datachannel = null;
    this.peerConnection = null;
    this.localStream = null;
    this.remoteStream = null;
    this.connected = false;
    this.iceCheckingTimer = null;
    this.peerConnectionReady = null;
    this.options = options || {};
    this.connection = options.connection;
    this.handleConnectionStateChange =
      this.handleConnectionStateChange.bind(this);
    this.initPeerConnection();
  }

  /**
   * Gets the local description from the underlying media implementation
   */
  // eslint-disable-next-line max-statements
  async getDescription() {
    const { options, peerConnection } = this;
    Logger.debug('SessionDescriptionHandler::getDescription:', options);
    this.localStream = options.stream;
    const offer = await peerConnection.createOffer(options.RTCOfferOptions);
    options.SDPModifiers.active.forEach(modifier => {
      offer.sdp = modifier(offer.sdp, options);
    });
    await peerConnection.setLocalDescription(offer);
    await this.peerConnectionReady;
    let { sdp } = peerConnection.localDescription;
    options.SDPModifiers.passive.forEach(modifier => {
      sdp = modifier(sdp, options);
    });
    Logger.debug('SessionDescriptionHandler::getDescription offer', sdp);
    return { type: 'offer', sdp };
  }

  /**
   * Set the remote description to the underlying media implementation
   */
  async setDescription(sessionDescription) {
    const { datachannel } = this;
    Logger.debug(
      'SessionDescriptionHandler::setDescription:',
      sessionDescription
    );
    if (sessionDescription && sessionDescription.sdp) {
      this.connection.seppMessaging = sessionDescription.sdp.includes(
        'a=eyeson-sepp-messaging'
      );
    }
    await this.peerConnection.setRemoteDescription(
      new RTCSessionDescription(sessionDescription)
    );
    this.options.remoteDescriptionUpdate(sessionDescription);
    if (datachannel.readyState === 'connecting') {
      await new Promise(resolve => {
        datachannel.onopen = () => {
          datachannel.onopen = null;
          resolve();
        };
      });
    }
  }

  // eslint-disable-next-line max-statements
  async updateDescription(sessionDescription) {
    const { options, peerConnection } = this;
    Logger.debug(
      'SessionDescriptionHandler::updateDescription:',
      sessionDescription
    );
    if (sessionDescription.type === 'offer') {
      try {
        await peerConnection.setRemoteDescription(
          new RTCSessionDescription(sessionDescription)
        );
        const answer = await peerConnection.createAnswer();
        options.SDPModifiers.active.forEach(modifier => {
          answer.sdp = modifier(answer.sdp, options);
        });
        await peerConnection.setLocalDescription(answer);
        this.options.remoteDescriptionUpdate(sessionDescription);
        let { sdp } = peerConnection.localDescription;
        options.SDPModifiers.passive.forEach(modifier => {
          sdp = modifier(sdp, options);
        });
        Logger.debug(
          'SessionDescriptionHandler::updateDescription answer',
          sdp
        );
        return { type: 'answer', sdp };
      } catch (error) {
        Logger.error('SessionDescriptionHandler::updateDescription', error);
      }
    } else if (sessionDescription.type === 'answer') {
      await peerConnection.setRemoteDescription(
        new RTCSessionDescription(sessionDescription)
      );
      this.options.remoteDescriptionUpdate(sessionDescription);
    }
    return null;
  }

  close() {
    Logger.debug('SessionDescriptionHandler::close');
    clearTimeout(this.iceCheckingTimer);
    if (this.peerConnection) {
      this.stopAllTracks();
      this.peerConnection.close();
      this.peerConnection = null;
      if (
        this.datachannel &&
        ['connecting', 'open'].includes(this.datachannel.readyState)
      ) {
        this.datachannel.close();
        this.datachannel = null;
      }
      Logger.debug(
        'SessionDescriptionHandler::close ' +
          'Stopped streams and closed peerConnection.'
      );
    }
  }

  // eslint-disable-next-line max-statements
  initPeerConnection() {
    const { options } = this;
    const pcOptions = this.buildPeerConnectionOptions();
    Logger.debug(
      'SessionDescriptionHandler::initPeerConnection with',
      pcOptions
    );
    let resolveReady = null;
    this.peerConnectionReady = new Promise(resolve => (resolveReady = resolve));
    try {
      const pc = new RTCPeerConnection(pcOptions);
      this.peerConnection = pc;
      this.initPeerConnectionStreamTracks();
      const channel = pc.createDataChannel('data', { negotiated: true, id: 0 });
      this.handleDatachannel(channel);
      pc.onicecandidate = ({ candidate }) => {
        if (!candidate) {
          resolveReady();
          return;
        }
        Logger.debug(
          'SessionDescriptionHandler::gotIceCandidate',
          candidate.candidate
        );
      };
      pc.onicegatheringstatechange = () => {
        if (pc.iceGatheringState === 'complete') {
          resolveReady();
        }
      };
      if (options.sendOnly === true) {
        pc.addEventListener(
          'connectionstatechange',
          this.handleConnectionStateChange
        );
      } else {
        pc.ontrack = this.handleOntrack.bind(this);
      }
      this.iceCheckingTimer = setTimeout(
        () => resolveReady(),
        _iceCheckingTimeout
      );
      this.applyCodecSelection();
    } catch (error) {
      Logger.error(
        'SessionDescriptionHandler::initPeerConnection failed:',
        error,
        this.connection.uaOptions
      );
    }
  }

  initPeerConnectionStreamTracks() {
    const { peerConnection, options } = this;
    if (isObject(options.sendEncodings)) {
      options.stream.getTracks().forEach(track => {
        try {
          const encodings = options.sendEncodings[track.kind];
          if (isObject(encodings)) {
            peerConnection.addTransceiver(track, {
              sendEncodings: [encodings],
              streams: [options.stream]
            });
          } else {
            peerConnection.addTrack(track, options.stream);
          }
        } catch (error) {
          Logger.error(
            'SessionDescriptionHandler::initPeerConnectionStreamTracks',
            error
          );
          peerConnection.addTrack(track, options.stream);
        }
      });
    } else {
      options.stream.getTracks().forEach(track => {
        peerConnection.addTrack(track, options.stream);
      });
    }
  }

  /**
   * Only signal (handleAccept) that we have a remote stream once ontrack
   * says so. Is called twice, once for audio and once for video track.
   */
  handleOntrack({ track, streams }) {
    const { options } = this;
    [this.remoteStream] = streams;
    Logger.debug('SessionDescriptionHandler::handleOntrack:', track.kind);
    track.onunmute = () => options.handleUnmute(track);
    options.handleAccept(this.remoteStream);
  }

  handleConnectionStateChange() {
    if (
      this.peerConnection.connectionState === 'connected' &&
      !this.connected
    ) {
      this.connected = true;
      this.options.handleAccept();
    }
  }

  handleDatachannel(channel) {
    this.datachannel = channel;
    this.connection.initDatachannel(channel);
  }

  /**
   * We need to re-format the iceServers here. SIP.js used to offer a
   * higher level api and we supplied the servers in a different format e.g:
   *
   * { stunServers: ["stun:stun1.visocon.com:3478"] }
   * { turnServers: [{ urls: [1,2,3], username: "bob", password: "nob" }] }
   *
   * We currently have the api set return the creds in that format.
   * The peerConnection however expects:
   *
   * {
   *   "iceServers": [
   *     { "urls": "stun:stun1.visocon.com:3478" },
   *     { "urls": [1,2,3], "username": "bob", "credential": "nob" }
   *   ]
   * }
   */
  buildPeerConnectionOptions() {
    const { options } = this;
    const iceServers = [];
    if (options.stun_servers.length > 0) {
      iceServers.push({ urls: options.stun_servers });
    }
    options.turn_servers.forEach(turnServer => {
      turnServer.credential = turnServer.password;
      iceServers.push(turnServer);
    });
    const pcOptions = {
      sdpSemantics: 'unified-plan',
      iceServers
    };
    return pcOptions;
  }

  getLocalStream() {
    return this.localStream;
  }

  getRemoteStream() {
    return this.remoteStream;
  }

  setStream(newStream) {
    // eslint-disable-next-line max-statements
    return new Promise((resolve, reject) => {
      let oldStream = this.getLocalStream();
      this.localStream = newStream;

      this.stopUnusedTracks(oldStream, newStream);

      let [audioTrack] = newStream.getAudioTracks();
      // in case of multiple streams, the preferred video track is the
      // canvas presentation stream
      let [videoTrack] = isCanvasPresentationStream(newStream)
        ? getCanvasTracks(newStream)
        : newStream.getVideoTracks();

      if (!this.tracksExist()) {
        resolve({ newStream: newStream, remoteStream: this.remoteStream });
        return;
      }
      const senders = this.peerConnection.getSenders();

      const audioSender = senders.find(
        sender => sender.track && sender.track.kind === 'audio'
      );
      const videoSender = senders.find(
        sender => sender.track && sender.track.kind === 'video'
      );
      Promise.all([
        audioSender ? audioSender.replaceTrack(audioTrack) : null,
        videoSender ? videoSender.replaceTrack(videoTrack) : null
      ])
        .then(() => {
          resolve({ newStream: newStream, remoteStream: this.remoteStream });
        })
        .catch(reject);
    });
  }

  stopUnusedTracks(oldStream, newStream) {
    if (this.connection.hasExternalStream) {
      return;
    }
    const unusedTracks = oldStream
      .getTracks()
      .filter(track => !newStream.getTracks().includes(track));
    unusedTracks.forEach(stopTrack);
  }

  tracksExist() {
    return Boolean(
      this.peerConnection.getSenders().find(sender => sender.track !== null)
    );
  }

  scaleResolution(factor) {
    // factor 0.5 => scaleResolutionDownBy 2
    try {
      const ratio = Math.max(1.0 / Math.max(factor, 0.1), 1.0);
      const videoSender = this.peerConnection
        .getSenders()
        .find(sender => sender.track.kind === 'video');
      const params = videoSender.getParameters();
      if (!params.encodings) {
        params.encodings = [{}];
      }
      if (params.encodings[0].scaleResolutionDownBy !== ratio) {
        params.encodings[0].scaleResolutionDownBy = ratio;
        videoSender.setParameters(params);
      }
    } catch (error) {
      Logger.error('SessionDescriptionHandler::scaleResolution failed:', error);
    }
  }

  // eslint-disable-next-line max-statements
  limitFramerate(fps) {
    try {
      const videoSender = this.peerConnection
        .getSenders()
        .find(sender => sender.track.kind === 'video');
      const params = videoSender.getParameters();
      if (!params.encodings) {
        params.encodings = [{}];
      }
      if (params.encodings[0].maxFramerate !== fps) {
        if (fps) {
          params.encodings[0].maxFramerate = fps;
        } else {
          Reflect.deleteProperty(params.encodings[0], 'maxFramerate');
        }
        videoSender.setParameters(params);
      }
    } catch (error) {
      Logger.error('SessionDescriptionHandler::limitFramerate failed:', error);
    }
  }

  setSendEncodings(sendEncodings = {}) {
    const senders = this.peerConnection.getSenders();
    // eslint-disable-next-line max-statements
    senders.forEach(async sender => {
      const encodings = sendEncodings[sender.track.kind];
      if (!isObject(encodings)) {
        return;
      }
      try {
        const params = sender.getParameters();
        if (!params.encodings) {
          params.encodings = [{}];
        }
        const [existing] = params.encodings;
        const allowed = ['maxBitrate', 'maxFramerate', 'scaleResolutionDownBy'];
        Object.keys(encodings).forEach(key => {
          if (allowed.includes(key)) {
            existing[key] = encodings[key];
          }
        });
        // heads-up: safari can't reset bitrate and framerate!
        Object.keys(existing).forEach(key => {
          if (!Reflect.has(encodings, key) && allowed.includes(key)) {
            Reflect.deleteProperty(existing, key);
          }
        });
        await sender.setParameters(params);
      } catch (error) {
        Logger.error('SessionDescriptionHandler::setSendEncodings', error);
      }
    });
  }

  // eslint-disable-next-line max-statements
  applyCodecSelection() {
    const { peerConnection, options } = this;
    let { audio, video } = options.codecSelection || {
      audio: null,
      video: null
    };
    if (!FeatureDetector.canSetCodecPreferences()) {
      return;
    }
    if (FeatureDetector.isIOSDevice() && !video) {
      video = fixIOSHWDecoderBug();
    }
    if (Array.isArray(audio) && audio.length > 0) {
      try {
        const audioTransceiver = peerConnection
          .getTransceivers()
          .find(
            transceiver =>
              transceiver.sender && transceiver.sender.track.kind === 'audio'
          );
        if (audioTransceiver) {
          audioTransceiver.setCodecPreferences(audio);
        }
      } catch (error) {
        Logger.error(
          'SessionDescriptionHandler::applyCodecSelection::audio',
          error
        );
      }
    }
    if (Array.isArray(video) && video.length > 0) {
      try {
        const videoTransceiver = peerConnection
          .getTransceivers()
          .find(
            transceiver =>
              transceiver.sender && transceiver.sender.track.kind === 'video'
          );
        if (videoTransceiver) {
          videoTransceiver.setCodecPreferences(video);
        }
      } catch (error) {
        Logger.error(
          'SessionDescriptionHandler::applyCodecSelection::video',
          error
        );
      }
    }
  }

  /**
   * Stop all tracks
   */
  stopAllTracks() {
    Logger.debug('SessionDescriptionHandler::stopAllTracks');
    let receivers = this.peerConnection.getReceivers
      ? this.peerConnection.getReceivers()
      : [];
    this.peerConnection
      .getSenders()
      .concat(receivers)
      .forEach(rtp => {
        if (rtp.track) {
          stopTrack(rtp.track);
        }
      });
  }
}

export default SessionDescriptionHandler;
/* eslint-enable max-lines */
